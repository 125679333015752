import logo from './gcLogo.svg';
import * as styled from './App.styles';

function App() {
  return (
    <styled.baseLayout >
      <styled.header>
        GC Transformation
        <p>
          Creating web applications to make your daily tasks easier.
        </p>
      </styled.header>
      <styled.logo>
        <img src={logo} className="App-logo" alt="logo" />

      </styled.logo>
      <styled.footer>
        This website is under construction.

      </styled.footer>
    </styled.baseLayout>
  );
}

export default App;
