import styled from "@emotion/styled";
import { px2vw } from "./jsUtils/px2vw";

export const baseLayout = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;

	text-align: center;
	background: #000;

	&& .App-logo {
		height: 40vmin;
		pointer-events: none;
	}

	@media (prefers-reduced-motion: no-preference) {
		.App-logo {
			animation: App-logo-spin infinite 20s linear;
		}
	}

	&& .App-header {
	}

	@keyframes App-logo-spin {
		from {
			transform: rotate(0deg);
		}
		to {
			transform: rotate(360deg);
		}
	}
`;

export const header = styled.div`
	color: #fff;
	font-size: ${px2vw(40)};
	font-weight: 600;
	padding: 10px;
	border-bottom: 1px solid #fffb;

	&& p {
    padding 0;
    margin: 0;
		font-size: ${px2vw(20)};
    color: #fffb;
	}
`;

export const logo = styled.div`
	background-color: #000;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
`;

export const footer = styled.div`
	color: #fffb;
	font-size: ${px2vw(20)};
	font-weight: 600;
	padding: 10px;
	border-top: 1px solid #fffb;
`;
